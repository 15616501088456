import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'

export const WorkPostTemplate = ({
  content,
  title,
  date,
  past_trailers
}) => {
  return (
  <>
  <nav className="main">
  <div className="grid-container">
    <div className="grid-x grid-margin-x align-middle">
      <div className="cell small-4 navbar-about">
        <a href='/' class='about-toggle'> Back </a>
      </div>
      <div className="cell small-4 navbar-logo text-center">
        <Link to="/" className="logo-link">
          <span className="logo-name">
            Benjamin Caron
          </span>
        </Link>
      </div>
      <div className="cell small-4 navbar-end text-right ">
        <a
          className="social"
          href="https://www.instagram.com/benjamincaron/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="var(--primary-colour)" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
        </a>
      </div>
    </div>
  </div>
  </nav>
  <section className="portfolio">
    <div className="grid-container portfolio-single" data-sal="slide-up" data-sal-duration="1000">
      <div className="grid-x align-middle">
        <div className="cell small-12">
          <div className="grid-x grid-margin-x grid-margin-y align-top">
            <div className="cell small-12 medium-8">
              {past_trailers.map(item => (
                  <React.Fragment>
                      <div className="flex-video" dangerouslySetInnerHTML={{ __html: item.trailer }} />
                      <p className="caption" dangerouslySetInnerHTML={{ __html: item.title }}/>
                  </React.Fragment>
              ))}

            </div>
            <div className="cell small-12 medium-4 large-3 large-offset-1 sticky">
              <h4 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h4>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
           </div>
        </div>
      </div>
    </div>
  </section>
  </>
  )
}

const WorkPost = ({ data }) => {

  const { wordpressPost: post } = data
  if(post.acf.custom_title){
    var title = post.acf.custom_title;
  } else {
    var title = post.title + ' | Benjamin Caron';
  }

  return (
    <Layout>
      <Helmet title={title} />
      <WorkPostTemplate
        content={post.acf.credits}
        past_trailers={post.acf.past_trailers}
        metadata={post.acf.metadata}
        custom_title={post.acf.custom_title}
        custom_description={post.acf.custom_description}
        title={post.title}
        date={post.date}
      />
    </Layout>
  )
}

export default WorkPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query WorkPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        custom_description
        custom_title
        credits
        metadata
        past_trailers {
          title
          trailer
        }
      }
    }
  }
`
